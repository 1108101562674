import React from 'react';
import { RegionToggle } from 'views/components/InfoPanel/children/ServiceRegionsView';
import TriangleIcon from 'views/components/icons/Triangle';
import DeleteServiceButton from './DeleteServiceButton';
import ToggleVisibilityOnServiceButton from './ToggleVisibilityOnServiceButton';
import cn from 'classnames';
import 'views/components/InfoPanel/children/TableStyles.scss';

const ServicesTable = ({ metro, projectId, phaseId, showServicesArray, changeShowServicesState }) => {
  return (
    <div className="table" headers={!!metro.services.length && ['Service', 'Availability', '', '']}>
      {metro.services.length > 0 ? (
        metro.services.map((serviceConnection) => {
          return (
            <React.Fragment key={serviceConnection.id}>
              <div className="column">
                <div
                  onClick={() => changeShowServicesState(serviceConnection.id, !showServicesArray[serviceConnection.id])}
                  className={cn(
                    'item first container',
                    { 'close-triangle-icon': !showServicesArray[serviceConnection.id] && !!serviceConnection.service.regions.length },
                    { clickable: !!serviceConnection.service.regions.length }
                  )}
                  title={serviceConnection.name}
                  data-testid={'expand-regions'}>
                  <p>{serviceConnection.name}</p> {!!serviceConnection.service.regions.length && <TriangleIcon width={6} height={5} color="black" />}
                </div>
                <div className="item">{!!serviceConnection.service.regions.length ? 'CSP' : serviceConnection.local ? 'Local' : 'Remote'}</div>
                <div className="item">
                  <ToggleVisibilityOnServiceButton projectId={projectId} phaseId={phaseId} service={serviceConnection} />
                </div>
                <div className="item last" onClick={() => changeShowServicesState(serviceConnection.id, false)}>
                  <DeleteServiceButton projectId={projectId} phaseId={phaseId} serviceConnection={serviceConnection} />
                </div>
              </div>
              {showServicesArray[serviceConnection.id] &&
                serviceConnection.service.regions
                  .map((region) => {
                    const checked = !!serviceConnection.regions.find((r) => r.equals(region));
                    const connectedMetro = region.connectedMetros.find((m) => m.equals(metro.id));
                    return {
                      region,
                      latency: connectedMetro ? connectedMetro.latency : null,
                      checked,
                    };
                  })
                  .filter(({ region, checked }) => checked || !region.deprecated)
                  .sort((a, b) => {
                    if (a.region.deprecated && !b.region.deprecated) {
                      return 1;
                    }

                    if (!a.region.deprecated && b.region.deprecated) {
                      return -1;
                    }

                    if (a.latency == null && b.latency == null) {
                      return 0;
                    }

                    if (a.latency == null && b.latency != null) {
                      return 1;
                    }

                    if (a.latency != null && b.latency == null) {
                      return -1;
                    }

                    return a.latency > b.latency ? 1 : -1;
                  })
                  .map(({ region, latency, checked }) => {
                    let className = 'column subColumn';
                    if (!checked) {
                      className += ' disabled';
                    }
                    return (
                      <div key={region.id} className={className} id={region.id}>
                        <div className="container item first">{region.code}</div>
                        <div className="item">{latency != null && latency >= 0 ? latency : '--'} ms</div>
                        <div className="item">{region.deprecated && <span>(deprecated)</span>}</div>
                        <div className="item last" />
                        <RegionToggle projectId={projectId} phaseId={phaseId} serviceConnection={serviceConnection} region={region} checked={checked} />
                      </div>
                    );
                  })}
            </React.Fragment>
          );
        })
      ) : (
        <div className="column">
          <p className="item">No services</p>
        </div>
      )}
    </div>
  );
};

export default ServicesTable;
