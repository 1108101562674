import React from 'react';
import cn from 'classnames';
import MapButton from '../MapButton';
import CloseIcon from 'views/components/icons/Close';
import { useMarketplaceSegments } from 'providers/MarketplaceProvider';
import { useCurrentProject } from 'providers/ProjectProvider';
import FeatureEvents from 'analytics/FeatureEvents';

const MarketplaceButton = ({ mapType, onChangeMapType, marketplaceSegment, onChangeMarketplaceSegment, open, changeOpenMapMenu, position }) => {
  const { marketplaceSegments } = useMarketplaceSegments();
  const { projectId, selectedPhaseId } = useCurrentProject();

  const onChangeSelectedSegment = (segment) => {
    onChangeMarketplaceSegment(segment);
    if (segment) {
      FeatureEvents.serviceDensityShown(projectId, selectedPhaseId, mapType);
    }
  };

  return (
    <MapButton
      data-testid={'marketplace-button'}
      className={cn('marketplace-button', { open, [mapType]: true, active: !!marketplaceSegment })}
      text={marketplaceSegment?.name ? `${marketplaceSegment?.name} (${mapType})` : 'Ecosystem Density'}
      position={position}
      onClick={() => changeOpenMapMenu('marketplace')}>
      <div className={cn('marketplace-menu', { visible: open })} data-testid={'marketplace-menu'}>
        <div className={'type-selection'}>
          <span onClick={() => onChangeMapType('heatmap')} className={cn({ selected: mapType === 'heatmap' })}>
            Heatmap
          </span>
          <span onClick={() => onChangeMapType('bubblePlot')} className={cn({ selected: mapType === 'bubblePlot' })}>
            Bubble Plot
          </span>
        </div>
        <ul className={cn('actions-list', { disabled: !marketplaceSegment })}>
          <li onClick={() => onChangeSelectedSegment(null)}>
            <p>Clear Selection</p>
            <CloseIcon width={10} height={10} color="#000000" />
          </li>
        </ul>
        <div className={'separator-line'} />
        <ul className={'segment-list'}>
          {!!marketplaceSegments?.length &&
            marketplaceSegments.map((segment) => (
              <div key={`segment-list-${segment.id}`}>
                <li onClick={() => onChangeSelectedSegment(segment)} className={cn({ selected: marketplaceSegment?.id === segment.id })}>
                  {segment.name}
                </li>
                {!!segment.subSegments?.length && (
                  <ul className={'subsegment-list'}>
                    {segment.subSegments.map((sub) => (
                      <li
                        onClick={() => onChangeSelectedSegment(sub)}
                        className={cn({ selected: marketplaceSegment?.id === sub.id })}
                        key={`segment-list-${sub.id}`}>
                        — {sub.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
        </ul>
      </div>
    </MapButton>
  );
};

export default MarketplaceButton;
